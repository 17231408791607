import plugin from "js-plugin";

class DatabaseListFilter {
    constructor($node) {
        this.$selects = $node.querySelectorAll('select');
        this.$event_buttons = $node.querySelectorAll('[data-js-type=event_type]');
        this.$inputs = $node.querySelectorAll('input');
        this.filter_items_map = new Map();
        this.filter_state = {};
        this.$pagination_links = document.querySelectorAll("[data-js-select=pagination_links]")
    }

    loaded() {
        this.$filter_items = [...document.querySelectorAll('[data-filter]')];
        // parse json only once and cache the results
        for (const $filter_item of this.$filter_items) {
            this.filter_items_map.set($filter_item, JSON.parse($filter_item.dataset['filter']))
        }

        this.filter_state = this._get_filter_state_from_url()

        this._install_event_listeners();

        this._apply_initial_filter_state()
    }

    _install_event_listeners = () => {

        this.$event_buttons.forEach(
            ($s) => $s.addEventListener('click', this._change_buttons($s))
        )

        this.$inputs.forEach(
            ($s) => {
                $s.addEventListener('change', this._changed_input($s))
                $s.addEventListener("keypress", (e) => {
                    if (e.key === "Enter") {
                        this._apply_filter_state()
                    }
                })
            },
        )

        const searchInput = document.querySelector("[data-js-select=search]")

        searchInput.addEventListener("input", this._changed_input(searchInput))
    }

    _changed_input = ($input) => (event) => {

        const inputValue = $input.value;
        const inputKey = $input.getAttribute("data-js-select")

        if (inputValue === "") {
            delete this.filter_state[inputKey]
        } else {
            this.filter_state[inputKey] = inputValue
        }
    }

    _get_filter_state_from_url = () => {

        const filter_state = {}
        const url = new URL(window.location.href);

        for (let [key, value_set] of url.searchParams.entries()) {
            if (["start", "end", "search"].includes(key)) {
                filter_state[key] = value_set || "";
            } else {
                filter_state[key] = new Set()
                for (const item of value_set.split(",")) {
                    filter_state[key].add(item)
                }
            }
        }
        return filter_state
    }

    _change_buttons = ($button) => (e) => {

        const classList = $button.classList

        if (classList.contains("active")) {
            classList.remove("active")
        } else {
            classList.add("active")
        }

        const key = $button.getAttribute("data-js-type");
        const value = $button.getAttribute("data-js-value");

        if (this.filter_state[key]) {
            if (this.filter_state[key].has(value)) {
                this.filter_state[key].delete(value)
                if (this.filter_state[key].size === 0 || this.filter_state[key] === "") {
                    delete this.filter_state[key];
                }
            } else {
                this.filter_state[key].add(value)
            }
        } else {
            this.filter_state[key] = new Set()
            this.filter_state[key].add(value)
        }
        console.log("applying filter state")
        this._apply_filter_state()
    }

    _apply_initial_filter_state = () => {

        for (const [key, value_set] of Object.entries(this.filter_state)) {

            if (!["start", "end", "search"].includes(key)) {
                for (const value of value_set) {

                    const $button = document.querySelector(`button[data-js-value="${CSS.escape(value)}"]`)

                    if ($button) {
                        $button.classList.add("active")
                    }
                }
            }
        }

        const url = new URL(window.location.href);

        const activePageNumber = url.searchParams.get("page") || "1";

        this.$pagination_links.forEach(($s) => {
                const pageNumber = $s.getAttribute("data-js-value")

                if (pageNumber === activePageNumber) {
                    $s.classList.add("active")
                } else {
                    $s.classList.remove("active")
                }
                url.searchParams.delete("page")
                url.searchParams.append("page", pageNumber)
                $s.setAttribute("href", url)
            }
        )
    }

    _apply_filter_state = () => {

        for (const [key, value] of Object.entries(this.filter_state)) {
            let $button = document.querySelector(`button[data-js-value="${value}"]`)
            if ($button) {
                $button.classList.add("active")
            }
        }

        const url = new URL(window.location.href);
        url.search = ""
        for (const [key, value] of Object.entries(this.filter_state)) {
            if (["start", "end", "search"].includes(key)) {
                url.searchParams.append(key, value);
            } else {
                url.searchParams.append(key, [...value].join(",").replace(/(^,)|(,$)/g, ""));
            }
        }

        url.searchParams.delete("page")

        //history.pushState({}, "", url);
        window.location = url;
    }
}

const DatabaseListFilterPlugin = {
    name: 'DatabaseListFilter',
    initializeWithSelector: ($node) => {
        const c = new DatabaseListFilter($node);
        c.loaded();
    }
};

// Register plugin
plugin.register(DatabaseListFilterPlugin);