import plugin from "js-plugin";

class CollapsibleSection {
    constructor($node) {
        this.$node = $node;
        this.$content = $node.querySelector('.content');
        this.$buttons = $node.querySelectorAll('.collapse-button');
        this.$title = $node.querySelector('.title');
        this.collapsed = false;

        for (const button of this.$buttons) {
            button.addEventListener('click', this.toggleCollapse.bind(this));
        }
        // also add event listener to title to improve UX
        this.$title.addEventListener('click', this.toggleCollapse.bind(this));
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    toggleCollapse() {
        if (this.collapsed) {
            this.$content.style.maxHeight = `${this.$content.scrollHeight}px`;
            this.$node.classList.remove('collapsed');
        } else {
            this.$content.style.maxHeight = null;
            this.$node.classList.add('collapsed');
        }
        this.collapsed = !this.collapsed;
    }

    handleResize() {
        // Adjust the maxHeight on resize only if the section is not collapsed
        if (!this.collapsed) {
            this.$content.style.maxHeight = `${this.$content.scrollHeight}px`;
        }
    }

    loaded() {
        // Initialize the section as collapsed
        this.toggleCollapse();
    }
}

const CollapsibleSectionPlugin = {
    name: 'CollapsibleSection',
    initializeWithSelector: ($node) => {
        const c = new CollapsibleSection($node);
        c.loaded();
    }
};

// Register plugin
plugin.register(CollapsibleSectionPlugin);